import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import * as common from "../common"
import SEO from "../components/seo"

export default ({ data }) => {
  const title = data.mysqlCompetitions.label
  data.allMysqlCompAverageBySeason.edges.forEach(edge => {
    edge.node.seasonString = common.seasonParamToString(edge.node.season)
  })
  const chartdata = JSON.stringify(
    data.allMysqlCompAverageBySeason.edges.map(edge => {
      return {
        season: edge.node.season,
        average: edge.node.average,
        seasonString: edge.node.seasonString,
      }
    })
  )

  return (
    <Layout>
      <SEO title={title} />
      <Helmet
        script={[
          {
            type: "text/javascript",
            innerHTML: `
                if(document.getElementById('canvas') == null){
                  window.onload = function() {
                    insertCompetitionBarchart('canvas', ${chartdata});
                };
                } else {
                  insertCompetitionBarchart('canvas', ${chartdata});
                }
                `,
          },
        ]}
      />
      <div>
        <h1>{title}</h1>
        <h2>Average attendance by season:</h2>
        <div className="subsection">
          {data.allMysqlCompAverageBySeason.edges.map((edge, key) => (
            <div
              key={key}
              className="row justify-content-center season-comp-list-item"
            >
              <div className="offset-1 col-5 col-md-3 text-left">
                <a
                  href={`/compseason/${edge.node.competition}/${edge.node.season}`}
                >
                  Season {edge.node.seasonString}
                </a>
              </div>
              <div className="col-5 col-md-3 text-right">
                {edge.node.average}
              </div>
            </div>
          ))}
        </div>

        <div
          className="row justify-content-center"
          style={{ margin: "30px 0 0 0" }}
        >
          <div className="col-12 col-md-8">
            <canvas id="canvas"></canvas>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($compId: String!) {
    mysqlCompetitions(mysqlId: { eq: $compId }) {
      label
    }
    allMysqlCompAverageBySeason(filter: { competition: { eq: $compId } }) {
      edges {
        node {
          id
          season
          average
          competition
        }
      }
    }
  }
`
